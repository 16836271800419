import React, { useState, useEffect } from "react";
// import { navigate } from "gatsby"
import ReactDOMServer from 'react-dom/server';
import GoogleMap from 'google-map-react';
import geolocator from 'geolocator';
import fetch from 'node-fetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDirections, faPhone, faMapMarker } from '@fortawesome/free-solid-svg-icons'
import {PhoneNumberUtil} from 'google-libphonenumber';
import LoadingSrc from '../../images/loading.gif';
import StampSrc from "../../images/stamp-beige.svg";
import { mapOptions } from './config';

import {
  Root,
  Map,
  Sidebar,
  Title,
  Body,
  Form,
  Input,
  SearchSubmit,
  // SignupSubmit,
  BuyOnlineTitle,
  BuyOnlineLink,
  // Tabs,
  // Tab,
  ResultsWrapper,
  Results,
  Result,
  ResultTitle,
  TotalResults,
  // Note,
  MenuButton,
  MenuButtonItem,
  MapOverlay,
  StampImg,
  InputContainer,
  InputLabel
} from "./StoreLocator.style";

const phoneUtil = PhoneNumberUtil.getInstance();

const googleApiKey = 'AIzaSyDX6p5nM5YNV8C4UymcNGqq8sL_Q64GINM';
const isSSR = typeof window === "undefined";

if(!isSSR) {
  geolocator.config({
    language: "en",
    google: {
        version: "3",
        key: googleApiKey
    }
  });
}

const StoreLocator = ({style}) => {
  let windowWidth = true;

  if (typeof window !== 'undefined') {
    windowWidth = window.innerWidth < 768 ? false : true;
  }

  const [zipCode, setZipCode] = useState("");
  const [email, setEmail] = useState("");
  // const [searchBy, setSearchBy] = useState("local");
  const [menuIsShow, setMenuIsShow] = useState(windowWidth);
  const [coordinates, setCoordinates] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [locations, setLocations] = useState([]);
  const [fitBounds, setFitBounds] = useState(true);
  const [googleMapObj, setGoogleMapObj] = useState();
  const [markers, setMarkers] = useState([]);

  // useEffect(() => {
  //   //push url change
  //   navigate("/?q=" + encodeURIComponent(zipCode), { replace: true })
  // }, [zipCode]);

  //react to coordinate changes.  hit the locator url
  useEffect(async () => {
    if(coordinates == null) {
      return;
    }
    setIsLoading(true);
    let response;
    try {
      response = await fetch(
        `https://${process.env.GATSBY_API_DOMAIN}/locator`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({coordinates}),
        },
      ).then(results => results.json());
    } catch (error) {
      // TODO: an error occurred
      console.error({ error });
    }
     console.info(response?.location);
    if(Array.isArray(response?.location)) {
      setLocations(response?.location);
    }
    setIsLoading(false);
  }, [JSON.stringify(coordinates)]);

  useEffect(() => {
    if(googleMapObj) {
      //remove markers that are no longer relevant
      //console.info({locations})
      markers.filter(m => !locations.map(l => l.id).includes(m.id) ).forEach(m => m.setMap(null));
      const newMarkers = [];
      const bounds = new googleMapObj.maps.LatLngBounds();
      (locations || []).forEach((loc, idx) => {
        let marker = markers.find(m => m.id === loc.id);
        if(marker == null) {
          const icon = {
            path: "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z", //"M19.2604167,55.8867188 C17.319445,53.0664068 14.8185758,49.5039068 11.7578125,45.1992188 C8.02517418,39.8554688 5.56163251,36.2558599 4.3671875,34.4003906 C2.65017418,31.7285156 1.49305499,29.4833979 0.895833333,27.6650391 C0.298611681,25.8466803 0,23.7499994 0,21.375 C0,17.5156244 0.970486681,13.9531244 2.91145833,10.6875 C4.85242999,7.42187557 7.46527835,4.82421818 10.75,2.89453125 C14.0347217,0.964844316 17.618055,0 21.5,0 C25.381945,0 28.9652783,0.964844316 32.25,2.89453125 C35.5347217,4.82421818 38.14757,7.42187557 40.0885417,10.6875 C42.0295133,13.9531244 43,17.5156244 43,21.375 C43,23.7499994 42.7013883,25.8466803 42.1041667,27.6650391 C41.506945,29.4833979 40.3498258,31.7285156 38.6328125,34.4003906 C37.4383675,36.2558599 34.9748258,39.8554688 31.2421875,45.1992188 L23.7395833,55.8867188 C23.2170133,56.6289068 22.4704867,57 21.5,57 C20.5295133,57 19.7829867,56.6289068 19.2604167,55.8867188 Z M22,30 C24.475,30 26.5937494,29.1187506 28.35625,27.35625 C30.1187506,25.5937494 31,23.475 31,21 C31,18.525 30.1187506,16.4062506 28.35625,14.64375 C26.5937494,12.8812494 24.475,12 22,12 C19.525,12 17.4062506,12.8812494 15.64375,14.64375 C13.8812494,16.4062506 13,18.525 13,21 C13,23.475 13.8812494,25.5937494 15.64375,27.35625 C17.4062506,29.1187506 19.525,30 22,30 Z",
            fillColor: '#cda668',
            strokeColor: '#00000',
            strokeWeight: 1,
            fillOpacity: 1,
            //anchor: new googleMapObj.maps.Point(100,0),
            // size: new googleMapObj.maps.Size(200, 200),
            // scaledSize: new google.maps.Size(100, 100),
            scale: 2.5,
            // scaledSize: new googleMapObj.maps.Size(60, 30),
            anchor: new googleMapObj.maps.Point(12, 24),
            strokeWeight: 0,
            // scale: .9
          }
          marker = new googleMapObj.maps.Marker({
            position: new googleMapObj.maps.LatLng(loc.lat, loc.long),
            map: googleMapObj.map,
            title: loc.dba,
            icon,
            optimized: false
          });
          marker.id = loc.id;
          marker.isOpen = false;

          marker.addListener("click", () => {
            event.stopPropagation();
            if(marker.isOpen === true) {
              marker.isOpen = false;
              googleMapObj.infowindow.close();
            } else {
              renderInfoWindow({loc, marker});
            }
          });
        }
        bounds.extend(marker.position);
        newMarkers.push(marker);
      });
      if(newMarkers.length > 0 && fitBounds) {
        googleMapObj.map.fitBounds(bounds);
      }
      setMarkers(newMarkers);
    }
  }, [JSON.stringify(locations)]);

  const detectUserLocation = () => {
    var options = {
      //enableHighAccuracy: true,
      timeout: 5000,
      maximumWait: 10000,     // max wait time for desired accuracy
      fallbackToIP: true,     // fallback to IP if Geolocation fails or rejected
    };
    if(!isSSR) {
      geolocator.locate(options, (err, location) => {
        if (err) return console.log(err);
        console.log({detectUserLocation: location});
        if(location.coords != null) {
          setCoordinates(location.coords);
        }
        if((location.address || {}).city != null) {
          setZipCode((location.address || {}).city);
        }
      });
    }
  }

  const handleSearchSubmit = async e => {
    e.preventDefault();
    // setIsLoading(true);
    if(zipCode !== '') {
      setFitBounds(true);
      let response;
      try {
        response = await fetch(
          `https://${process.env.GATSBY_API_DOMAIN}/geocode`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({address: zipCode}),
          },
        ).then(results => results.json());
      } catch (error) {
        // TODO: an error occurred
        console.error({ error });
      }
      const gps = ((((response || {}).results || [])[0] || {}).geometry || {}).location;
      if(gps) {
        setCoordinates({longitude: gps.lng, latitude: gps.lat});
      }
      // console.info({response});
    }
  };

  const renderInfoWindow = ({loc, marker}) => {
    markers.forEach(m => m.isOpen = false);
    const directionsUrl = `https://maps.google.com/?q=${encodeURIComponent(`${loc.street},${loc.city},${loc.state}`)}`;
    const content = ReactDOMServer.renderToString(<div style={{padding:'10px'}}><h3 style={{color: 'black', fontWeight: 'bold'}} dangerouslySetInnerHTML={{__html: loc.dba}} /><p style={{color: 'black', lineHeight: '30px'}}>
    <a target="_blank" style={{lineHeight: '22px'}} href={directionsUrl}>{loc.street}<br />{loc.city}, {loc.state} {loc.zip}</a><br />
    <FontAwesomeIcon icon={faPhone} />&nbsp;&nbsp;{phoneUtil.formatInOriginalFormat(phoneUtil.parse(loc.phone, 'US'), 'US')}<br />
    <FontAwesomeIcon icon={faDirections} />&nbsp;&nbsp;<a target="_blank" href={directionsUrl}>Directions</a>
    </p></div>);
    marker.isOpen = true;
    googleMapObj.infowindow.setContent(content);
    googleMapObj.infowindow.open({
      anchor: marker,
      map: googleMapObj.map,
      shouldFocus: false}
    );
  };

  return (
    <Root id="locator" style={style} onClick={e => {
      e.stopPropagation();
      googleMapObj.infowindow.close();
    }}>
      <Map className={menuIsShow ? 'sidebarExpanded' : null}>
        { false && isLoading && <MapOverlay>
          <img style={{maxWidth: '60px', marginTop: '-2px'}} src={LoadingSrc} alt="" />
          <span>FINDING STORES NEARBY...</span>
        </MapOverlay>}

        <GoogleMap
          bootstrapURLKeys={{
            key:
            googleApiKey,
            libraries:['geometry'],
          }}
          center={coordinates ? [coordinates.latitude, coordinates.longitude] : [39.8283, -98.5795]}
          zoom={coordinates ? 9 : 5} //coordinates ? 9 : 5
          options={maps => {
            return mapOptions(maps);
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            const infowindow = new maps.InfoWindow({pixelOffset: new maps.Size(0, -20)});
            detectUserLocation();
            setGoogleMapObj({map, maps, infowindow});
            maps.event.addListener(map, 'dragend', () => { 
              setFitBounds(false);
              const center = map.getCenter();
              const bounds =  map.getBounds();
              const cornerCoordinates = bounds.getNorthEast();
              const distanceMeters = maps.geometry.spherical.computeDistanceBetween (center, cornerCoordinates);
              const distanceMiles = 0.000621371 * distanceMeters;
              //setZipCode(`${center.lat().toFixed(4)},${center.lng().toFixed(4)}`);
              setCoordinates({latitude: center.lat(), longitude: center.lng(), distance: distanceMiles})
            });
            map.addListener("zoom_changed", () => {
              const center = map.getCenter();
              const bounds =  map.getBounds();
              const cornerCoordinates = bounds.getNorthEast();
              const distanceMeters = maps.geometry.spherical.computeDistanceBetween (center, cornerCoordinates);
              const distanceMiles = 0.000621371 * distanceMeters;
              //setZipCode(`${center.lat().toFixed(4)},${center.lng().toFixed(4)}`);
              setCoordinates({latitude: center.lat(), longitude: center.lng(), distance: distanceMiles})
            });
          }}
        />
      </Map>

      <Sidebar $isShow={menuIsShow} style={{zIndex: 99}}>
        <Title>Find Nearby</Title>
        <Body>
          <Form onSubmit={handleSearchSubmit}>
            <InputContainer>
              <InputLabel htmlFor="location-search">Location</InputLabel>
              <Input
                id="location-search"
                type="text"
                name="zipCode"
                placeholder="Location"
                value={zipCode}
                onChange={e => setZipCode(e.target.value)}
              />
            </InputContainer>
            <SearchSubmit type="submit">
              <svg role="img" aria-labelledby="nearby-search-title" width="1em" height="1em" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                <title id="nearby-search-title">Search Nearby</title>
                <path fill="#ce9b61" d="M 22.957 20.627 C 23.029 20.667 23.097 20.713 23.159 20.767 C 24.604 22.208 26.046 23.65 27.487 25.093 C 27.955 25.523 28.121 26.191 27.909 26.791 C 27.735 27.41 27.212 27.868 26.575 27.96 C 26.042 28.065 25.492 27.889 25.121 27.492 C 23.673 26.045 22.224 24.598 20.773 23.152 C 20.723 23.09 20.68 23.022 20.646 22.95 C 14.773 27.057 6.656 23.266 6.035 16.126 C 5.414 8.986 12.756 3.852 19.25 6.885 C 24.478 9.327 26.249 15.888 22.958 20.629 Z M 15.306 21.234 C 19.862 21.209 22.682 16.262 20.383 12.329 C 19.341 10.546 17.444 9.436 15.379 9.399 C 10.824 9.318 7.89 14.199 10.098 18.184 C 11.146 20.076 13.143 21.245 15.306 21.234 Z" />
              </svg>
            </SearchSubmit>
          </Form>

          <BuyOnlineTitle>Can't find it locally?</BuyOnlineTitle>
          <BuyOnlineLink href="https://olesmoky.com/collections/james-ownby-reserve/products/james-ownby" type="button" target="_blank">Buy Online</BuyOnlineLink>
          {/* <BuyOnlineLink style={{color: 'black'}} href="https://shop.jamesownbyreserve.com" type="button">Copy Link</BuyOnlineLink> */}
          {/* <Tabs>
            <Tab className='--active'>
              <button onClick={() => console.info('ss')}>Local</button>
            </Tab>
            <Tab className=''>
              <button onClick={() => console.info('ss')}>Online</button>
            </Tab>
          </Tabs> */}

           <TotalResults role="status">{(locations || []).length} Result{(locations || []).length === 1 ? '': 's'}</TotalResults>
           <ResultsWrapper>
          <Results>
          {(locations || []).length > 0 &&
            locations.map(loc => {
              const directionsUrl = `https://maps.google.com/?q=${encodeURIComponent(`${loc.street},${loc.city},${loc.state}`)}`;
              return (<Result key={loc.id}>
              <button type="button" onClick={() => {
                const marker = markers.find(m => m.id === loc.id);
                renderInfoWindow({loc, marker});
                googleMapObj.map.panTo(new googleMapObj.maps.LatLng(loc.lat,loc.long));
                setZipCode(`${loc.street}, ${loc.city}, ${loc.state} ${loc.zip}`);
              }}><ResultTitle><span dangerouslySetInnerHTML={{__html: loc.dba}} /> &nbsp;&nbsp; <span style={{fontSize: '80%',fontWeight: 'normal'}}>{parseFloat(loc.distance).toFixed(1)} Mi</span></ResultTitle>
              <p style={{textAlign: 'left', marginBottom: '5px'}}>
                {loc.street}<br />{loc.city}, {loc.state} {loc.zip}
              </p>
              </button>
              <p>
                <span style={{marginBottom: '5px'}} ><FontAwesomeIcon icon={faPhone} />&nbsp;&nbsp;<a href={`tel:${phoneUtil.format(phoneUtil.parse(loc.phone, 'US'), 'E.164')}`}>{phoneUtil.formatInOriginalFormat(phoneUtil.parse(loc.phone, 'US'), 'US')}</a></span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span><FontAwesomeIcon icon={faDirections} />&nbsp;&nbsp;<a target="_blank" href={directionsUrl}>Directions</a></span>
              </p>
            </Result>)
            })}
         
          


          { (locations || []).length === 0 && <p style={{fontSize: '16px', lineHeight: '26px'}}>
          No results nearby. Please try modifying your search criteria and try again.
        </p>}
        </Results>          <StampImg src={StampSrc} alt="" style={{zIndex: -1}} /></ResultsWrapper>
          {/* <Note>Sign up to get notified when we’ll be in your area.</Note>
          <Form onSubmit={handleSignupSubmit}>
            <Input
              type="text"
              name="email"
              placeholder="Email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <SignupSubmit type="submit">Sign up</SignupSubmit>
          </Form> */}
        </Body>
      </Sidebar>

      <MenuButton aria-label={menuIsShow ? "Close" : "Open"} type="button" onClick={() => setMenuIsShow(!menuIsShow)}>
        <MenuButtonItem $menuIsShow={menuIsShow} />
        <MenuButtonItem $menuIsShow={menuIsShow} />
        <MenuButtonItem $menuIsShow={menuIsShow} />
        <MenuButtonItem $menuIsShow={menuIsShow} />
      </MenuButton>
    </Root>
  )
}

export default StoreLocator
