import styled from "styled-components";

export const Root = styled.div`
  position: relative;
  min-height: 750px;
  background-color: #e9dfd0;
  overflow: hidden;

  @media (min-width: 1024px) {
    display: flex;
  }
`;

export const Map = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: all 300ms;
  transition-delay: 0;

  &.sidebarExpanded {
    @media (max-width: 1023px) {
    width: calc(100% - 375px);
    transition-delay: 300ms;
  }

    @media (min-width: 1024px) {
      width: calc(100% - 477px);
      transition-delay: 300ms;
    }
  }
`;

export const MapOverlay = styled.div`
    position: absolute; 
    z-index: 1; 
    width: 100%;
    height: 100%; 
    background-color: rgba(255,255,255,.6);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    letter-spacing: 0.1em;
    font-family: 'DharmaGothic';
    pointer-events: none;
    text-align: center;
`;

export const Sidebar = styled.div`
  position: absolute;
  top: 0;
  right: ${p => p.$isShow ? 0 : '-530px'};
  bottom: 0;
  background-color: #e9dfd0;
  box-shadow: -5px 0px 11px 3px rgba(0, 0, 0, 0.15);
  transition: all 300ms;

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 375px;
    padding-top: 59px;
    padding-bottom: 40px;
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (min-width: 1024px) {
    width: 477px;
    padding-top: 59px;
    padding-bottom: 68px;
    padding-left: 59px;
    margin-left: auto;

    &::before {
      position: absolute;
      top: 0;
      left: -7px;
      display: block;
      content: "";
      background-image: radial-gradient(circle, transparent 6px, #e9dfd0 7px);
      background-size: 15px 21px;
      background-position-x: -7px;
      width: 7px;
      height: 100%;
      z-index: 1;
    }
  }
`;

export const Title = styled.h2`
  color: #141f35;
  font-family: "GreatWestern";
  letter-spacing: 0.05em;
  line-height: 50px;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 26px;
  }

  @media (min-width: 1024px) {
    font-size: 32px;
  }
`;

export const Body = styled.div`
  width: 100%;
  max-width: 372px;
`;

export const Form = styled.form`
  display: flex;
  margin-bottom: 24px;
  align-items: flex-end;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InputLabel = styled.label`
  color: #141f35;
  font-size: 14px;
  margin-bottom: 6px;
`;

export const Input = styled.input`
  flex-grow: 1;
  height: 40px;
  margin-right: 12px;
  padding: 0 16px;
  color: #141f35;
  background-color: #fff;
  font-size: 18px;
  border: 0 none;

  &::placeholder {
    opacity: 0.8;
  }
`;

export const SearchSubmit = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 40px;
  font-size: 30px;
  border: 1px solid #ce9b61;
`;

export const SignupSubmit = styled.button`
  height: 40px;
  color: #ce9b61;
  font-family: 'DharmaGothic';
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border: 1px solid #ce9b61;

  @media (max-width: 1023px) {
    width: 90px;
    font-size: 24px;
  }

  @media (min-width: 1024px) {
    width: 125px;
    font-size: 28px;
  }
`;

export const BuyOnlineTitle = styled.h3`
  font-size: 18px;
  font-weight: 800;
  margin-botom: 0px;
`;

export const BuyOnlineLink = styled.a`
  height: 42px;
  margin-top: 12px;
  margin-bottom: 16px;
  padding: 0 16px;
  color: ${p => p.$isShop ? '#e9dfd0' : '#ce9b61'};
  font-family: "DharmaGothic";
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  line-height: 42px;
  border: 1px solid ${p => p.$isShop ? '#e9dfd0' : '#ce9b61'};
  white-space: nowrap;
`;


// export const Tabs = styled.ul`
//   display: flex;
//   align-items: center;
//   margin-top: 35px;
// `;

// export const Tab = styled.li`
//   color: #141f35;
//   font-family: "GreatWestern";
//   font-size: 22px;
//   letter-spacing: 0.05em;
//   line-height: 49px;
//   text-transform: uppercase;

//   &:not(:last-of-type) {
//     margin-right: 26px;
//   }

//   &.--active {
//     color: #ce9b61;
//   }
// `;

export const ResultsWrapper = styled.div`
  border-top: 1px solid #ce9b61;
  margin-top: 10px;
  margin-bottom: 24px;
  padding: 24px 0;
`;

export const Results = styled.ul`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 400px;
`;

export const Result = styled.li`
  color: #141f35;
  font-size: 16px;
  line-height: 20px;
  z-index: 99;
  
  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
`;

export const ResultTitle = styled.h4`
  margin-bottom: 4px;
  font-weight: 800;
  text-align: left;
  margin-bottom: 0px
`;

export const TotalResults = styled.div`
  color: #141f35;
  font-size: 12px;
  font-weight: 800;
  line-height: 20px;
`;

export const Note = styled.p`
  margin: 24px 0;
  color: #141f35;
  font-size: 16px;
  line-height: 20px;
`;

export const StampImg = styled.img`
  position: absolute;

  @media (max-width: 1023px) {
    bottom: 70px;
    right: -90px;
    width: 270px;
  }

  @media (min-width: 1024px) {
    bottom: 80px;
    right: -80px;
  }
`;

export const MenuButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  transform: rotate(0deg);
  transition: 500ms ease-in-out;
  z-index: 99;

  @media (min-width: 1024px) {
    /* display: none; */
  }
`;

export const MenuButtonItem = styled.span`
  display: block;
  position: absolute;
  top: 20px;
  left: 4px;
  width: 24px;
  height: 4px;
  background-color: #141f35;
  opacity: 1;
  transform: rotate(0deg);
  transition: 250ms ease-in-out;

  span {
    position: absolute;
  }

  &:nth-child(1) {
    top: ${p => (p.$menuIsShow ? "14px" : "6px")};
    width: ${p => p.$menuIsShow && "0%"};
    left: ${p => p.$menuIsShow && "50%"};
  }

  &:nth-child(2),
  &:nth-child(3) {
    top: 14px;
  }

  &:nth-child(2) {
    transform: ${p => p.$menuIsShow && "rotate(45deg)"};
  }

  &:nth-child(3) {
    transform: ${p => p.$menuIsShow && "rotate(-45deg)"};
  }

  &:nth-child(4) {
    top: ${p => (p.$menuIsShow ? "14px" : "22px")};
    width: ${p => p.$menuIsShow && "0%"};
    left: ${p => p.$menuIsShow && "50%"};
  }
`;
